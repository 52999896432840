<template>
  <Disclosure as="nav">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton
            class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-cyan-950 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span class="absolute -inset-0.5" />
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div
          class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
        >
          <nuxt-link
            to="/terminais"
            @click="updateCurrentNavigationItem('/terminais')"
          >
            <div class="flex-shrink-0">
              <img
                class="h-10 w-auto"
                src="/img/saudetv.png"
                alt="Your Company"
              />
            </div>
          </nuxt-link>

          <div
            class="hidden sm:ml-6 sm:flex sm:items-center sm:justify-center flex-1"
          >
            <div class="flex space-x-4">
              <NuxtLink
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.current
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-400 hover:bg-gray-700 hover:text-white',
                  'px-3 py-2 text-sm font-medium flex items-center transition-colors duration-300 ease-in-out relative',
                  item.current ? 'space-x-2' : '',
                ]"
                :style="
                  item.current
                    ? 'background-color: #124559'
                    : 'hover: background-color: #124559'
                "
                :aria-current="item.current ? 'page' : undefined"
                activeClass="teste"
                @click="setActiveMenu(item.name)"
                @mouseover="hoveredName = item.name"
                @mouseout="hoveredName = ''"
              >
                <Icon
                  v-if="item.current"
                  :icon="item.icon"
                  class="h-5 w-5 text-white transition-all duration-300 ease-in-out"
                />
                <Icon
                  v-else
                  :icon="item.icon"
                  class="h-5 w-5 text-gray-400 transition-all duration-0 ease-in-out"
                />
                <span
                  v-if="item.current"
                  class="transition-all duration-300 ease-in-out text-lg font-semibold leading-normal font-montserrat"
                >
                  {{ item.name }}
                </span>
                <span
                  v-if="hoveredName === item.name && !item.current"
                  class="absolute bg-black text-white text-xs rounded py-1 px-2 -bottom-6 left-1/2 transform -translate-x-1/2 translate-y-2 transition-all duration-300 ease-in-out"
                >
                  {{ item.name }}
                </span>
              </NuxtLink>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <button
            type="button"
            class="relative rounded-full p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            <span class="absolute -inset-1.5" />
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-7 w-7" aria-hidden="true" />
          </button>

          <!-- <button @click="toggleDarkMode">
            Switch to {{ isDarkMode.value ? "Light" : "Dark" }} Mode
          </button> -->

          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <div class="flex-shrink-0 flex items-center pr-2">
              <MenuButton
                class="relative h-9 w-9 flex rounded-full border-2 border-[#124559] text-sm focus:outline-none focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 mr-1 justify-center items-center"
              >
                <span class="sr-only">Open user menu</span>
                <img src="/img/logo.png" alt="" class="w-7 h-7" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <a
                    :href="`/meu-perfil/${authenticatedUser?._id}`"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 flex items-center',
                    ]"
                  >
                    <Icon icon="iconamoon:profile-fill" class="mr-2 h-5 w-5" />
                    Meu perfil
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 flex items-center',
                    ]"
                  >
                    <Icon icon="ic:baseline-settings" class="mr-2 h-5 w-5" />
                    Configurações
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <button
                    @click="logout"
                    :class="[
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 w-full text-left flex items-center',
                    ]"
                  >
                    <Icon icon="ic:outline-logout" class="mr-2 h-5 w-5" />
                    Sair da conta
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
          <span class="font-montserrat text-base font-semibold text-[#333333]">
            {{ authenticatedUser.name }}
          </span>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          as="a"
          :to="item.href"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block rounded-md px-3 py-2 text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
import { useAuthUserStore } from "~/store/users";

const store = useAuthUserStore();
const { authenticatedUser } = storeToRefs(store);

// const isDarkMode = ref(false);

const updateCurrentNavigationItem = (targetHref) => {
  navigation.value.forEach((item) => {
    item.current = item.href === targetHref;
  });
};

const hoveredName = ref("");

const navigation = ref([
  {
    name: "Assinantes",
    href: "/assinantes",
    current: false,
    icon: "fluent:folder-people-20-regular",
  },
  {
    name: "Terminais",
    href: "/terminais",
    current: false,
    icon: "mi:computer",
  },
  {
    name: "Conteúdos",
    href: "/conteudos",
    current: false,
    icon: "streamline:entertainment-play-list-1-screen-television-display-player-movies-movie-tv-media-players-video",
  },
  { name: "Usuários", href: "/usuarios", current: false, icon: "bi:person" },
  {
    name: "Playlists",
    href: "/playlists",
    current: false,
    icon: "solar:playlist-outline",
  },
  {
    name: "Anunciar",
    href: "/",
    current: false,
    icon: "mingcute:announcement-line",
  },
  {
    name: "RSS",
    href: "/",
    current: false,
    icon: "ph:rss-fill",
  },
  {
    name: "Relatórios",
    href: "/relatorios",
    current: false,
    icon: "oui:app-reporting",
  },
]);

const setActiveMenu = (name) => {
  navigation.value.forEach((item) => {
    item.current = item.name === name;
  });
};

const logout = () => {
  const authCookie = useCookie("token");
  authCookie.value = null;
  navigateTo("/auth");
};

// onMounted(() => {
//   isDarkMode.value = localStorage.getItem("dark-mode") === "true";
// });

// const toggleDarkMode = () => {
//   isDarkMode.value = !isDarkMode.value;
// };

// watch(isDarkMode, (newValue) => {
//   localStorage.setItem("dark-mode", newValue.toString());
// });
</script>
