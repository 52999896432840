<template>
  <div class="bg-teal-50 dark:bg-cyan-950 h-full min-h-screen">
    <BaseNavbar />
    <div class="flex justify-center p-6">
      <div
        class="max-w-6xl w-full bg-transparent overflow-hidden dark:bg-cyan-800"
      >
        <NuxtPage> </NuxtPage>
      </div>
    </div>
  </div>
</template>
